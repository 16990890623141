import React from "react"
import { Row, Col, Container } from "reactstrap"
import HelmetTitle from "../components/utils"
import dataeng from "../images/data-engineering-pic-icon.png"
import dataeng01 from "../images/dataeng01.png"
import dataeng02 from "../images/dataeng02.png"
import dataeng03 from "../images/dataeng03.png"
import dataeng04 from "../images/dataeng04.png"
import dataeng05 from "../images/dataeng05.png"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-data-eng.scss"
import DownloadPDFForm from "../components/DownloadPDFForm"

const DataEngineeringPage = () => {
  const title = "Data Engineering",
    tagline = `Foretheta’s mission is to help companies analyze data to make better decisions. Enhancing 
      productivity, increasing sales and customer satisfaction are the main advantages that Big data and 
      AI can give to business owners. Studies show that companies adopting AI and ML have boosted their 
      performance. At Foretheta, we’re deeply focused on solving data wrangling and help your business 
      thrive.`

  return (
    <Layout>
      <div className="data-engineering mb-4">
        <HelmetTitle title="Data Engineering" />
        <div className="data-eng-banner pt-6 pb-6 mb-4 text-white text-justify">
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <h1 className="mb-5 font-weight-bold">{title} </h1>
              <h3 className="mb-5 text-justify">{tagline}</h3>
            </Col>
          </Row>
        </div>
        <Container fluid>
          <Row className="my-5">
            <Col
              xl={{ size: 4, offset: 1 }}
              lg={{ size: 3, offset: 1 }}
              md={{ size: 4, offset: 0 }}
              sm={{ size: 6, offset: 2 }}
              xs={{ size: 3, offset: 1 }}
            >
              <img src={dataeng} className="people-img" alt="Team Meeting" />
            </Col>
            <Col
              xl={{ size: 5, offset: 1 }}
              lg={{ size: 5, offset: 2 }}
              md={{ size: 6, offset: 2 }}
              xs={12}
            >
              <h4 className="skill-set font-weight-bold">
                A proven skill set and framework
              </h4>
              <p>
                Today everyone is talking about Big Data, Artificial
                Intelligence, and Machine Learning. These overlapping concepts
                are tightly related to complex operations, even though they may
                intend to achieve some basic needs; companies are looking,
                nowadays, to automate and unify processes and infrastructures,
                analyze customer preferences, and process ERP data for better
                performance and sales.
              </p>
              <p>
                Companies that want to be more productive, or develop new
                winning solutions will need to embrace data science to make it
                happen. Foretheta can help!
              </p>
            </Col>
          </Row>
        </Container>
        <div className="form-banner pt-4 mb-4 text-white px-md-0 px-sm-5 px-5">
          <Row>
            <Col md={{ size: 5, offset: 1 }} className="py-4">
              <h3 className="font-weight-bold">
                How to help your business with Big data and AI?
              </h3>
              <p>
                We’ve seen a lot of businesses struggle to get started over the
                years, and some that have thrived. Companies that succeed in
                their Big data/AI projects start simple. They start with a
                single goal or project, but they have a strategic vision for the
                future. The following white paper will help you understand some
                of the key elements on how to help your business with Big data
                and AI:
              </p>
              <ul>
                <li>From where the data come?</li>
                <li>Why exploiting this data?</li>
                <li>The myth of the big investment</li>
                <li>Experience and expertise are keys</li>
                <li>Beyond Big Data</li>
                <li>Applications of AI/ML</li>
              </ul>
            </Col>
            <Col md={5} className="py-3">
              <DownloadPDFForm title="Data Engineering Prospect" />
            </Col>
          </Row>
        </div>
        <h4 className="text-center services-header my-5 font-weight-bold">
          Our Data Engineering Services
        </h4>
        <Container fluid>
          <Row>
            <Col
              lg={{ size: 3, offset: 0 }}
              sm={{ size: 5, offset: 1 }}
              xs={{ size: 10, offset: 1 }}
              className="mb-5 large-screen"
            >
              <div className="text-center my-3">
                <img src={dataeng01} alt="Review icon" />
              </div>
              <p className="text-center object-p">
                Reviewing your current data architecture to analyze data sources
                and define data lakes
              </p>
            </Col>

            <Col
              lg={{ size: 3, offset: 0 }}
              sm={{ size: 5, offset: 1 }}
              xs={{ size: 10, offset: 1 }}
              className="mb-5 large-screen"
            >
              <div className="text-center my-3">
                <img src={dataeng02} alt="Cleaning process icon" />
              </div>
              <p className="text-center">
                Cleaning, processing, and transforming data into usable formats
                for model development
              </p>
            </Col>
            <Col
              lg={{ size: 3, offset: 0 }}
              sm={{ size: 5, offset: 1 }}
              xs={{ size: 10, offset: 1 }}
              className="mb-5 large-screen"
            >
              <div className="text-center my-3">
                <img src={dataeng03} alt="Building process icon" />
              </div>
              <p className="text-center">
                Building data pipelines that gather, process, store, and help
                access data
              </p>
            </Col>

            <Col
              lg={{ size: 3, offset: 3 }}
              sm={{ size: 5, offset: 1 }}
              xs={{ size: 10, offset: 1 }}
              className="mb-5"
            >
              <div className="text-center my-3">
                <img src={dataeng04} alt="Analytics icon" />
              </div>
              <p className="text-center">
                Consult on selecting the best fitting big data analytics tools
                and products for your project
              </p>
            </Col>
            <Col
              lg={{ size: 3, offset: 0 }}
              sm={{ size: 6, offset: 3 }}
              xs={{ size: 10, offset: 1 }}
              className="mb-5 mx-lg-5"
            >
              <div className="text-center my-3">
                <img src={dataeng05} alt="Data managing icon" />
              </div>
              <p className="text-center">
                Helping choose among big data platforms for managing your data
                infrastructures
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default DataEngineeringPage
