import React from "react"
import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap"
import Reaptcha from "reaptcha"
import { withPrefix } from "gatsby"

class DownloadPDFForm extends React.Component {
  constructor(props) {
    super(props)
    this.onVerify = this.onVerify.bind(this)
    this.postData = this.postData.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.setFormValues = this.setFormValues.bind(this)
    this.state = {
      name: "",
      company: "",
      email: "",
      isVerified: false,
      isLoading: false,
      message: null
    }
  }

  onVerify(response) {
    if (response) {
      this.setState({
        isVerified: true
      })
    }
  }

  async postData(url = "", data = {}) {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
  }

  async submitForm(event) {
    event.preventDefault()
    event.persist()
    const { name, company, email, isVerified } = this.state
    const { title } = this.props

    if (isVerified) {
      this.setState({ isLoading: true })
      try {
        await this.postData(`${process.env.GATSBY_CONTACT_API_URI}`, {
          name,
          company,
          email,
          title
        })
        this.captcha.reset()
        event.target.reset()
        return this.downloadLink.click()
      } catch (error) {
        this.setState({ message: error.message })
      } finally {
        this.setState({ isLoading: false })
      }
    } else {
      this.setState({
        ...this.state,
        message: "Please verify that you are a human!"
      })
    }
  }

  setFormValues(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const { name, email, company, isLoading } = this.state
    return (
      <div className="contact-div px-3 py-3">
        <h4 className="free-paper font-weight-bold mb-4">Free white paper</h4>
        <Form onSubmit={this.submitForm}>
          <FormGroup>
            <Label for="name">
              Name:<span className="text-danger"> *</span>
            </Label>
            <Input value={name} onChange={this.setFormValues} type="text" name="name" required />
          </FormGroup>
          <FormGroup>
            <Label for="company">
              Company:<span className="text-danger"> *</span>
            </Label>
            <Input value={company} onChange={this.setFormValues} type="text" name="company" required />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              Email:<span className="text-danger"> *</span>
            </Label>
            <Input value={email} onChange={this.setFormValues} type="email" name="email" required />
          </FormGroup>
          <FormGroup>
            <Reaptcha
              ref={e => (this.captcha = e)}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onVerify={this.onVerify}
            />
          </FormGroup>
          <p className="text-danger"> {this.state.message} </p>
          <Button type="submit" className="my-2">
            {isLoading ? <Spinner size="sm" className="mr-2" /> : null}
            Download
          </Button>
          {isLoading ? (
            <a
              className="d-none"
              aria-label="download data engineering PDF"
              ref={e => (this.downloadLink = e)}
              href={withPrefix("/Data_Engineering_Whitepaper.pdf")}
              download
            >
              &nbsp;
            </a>
          ) : null}
        </Form>
      </div>
    )
  }
}

export default DownloadPDFForm
